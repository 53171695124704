import React from 'react'
import { Helmet } from "react-helmet"
import PortfolioSection from '../../components/Sections/PortfolioSection'

import MainTemplate from '../../components/Template/MainTemplate'

export default function Portfolio() {
  return (
    <MainTemplate>
      <Helmet>
        <title>Portfolio - Dicka Ismaji</title>
      </Helmet>
      <div className="mb-24">
        <PortfolioSection />
      </div>
    </MainTemplate>
  )
}